import "./PageForStudents.css";
import { Link } from "react-router-dom";
import RepaymentApplicationForm from "./repaymentApplicationForm";

const PageForStudents = () => {
  return (
    <div className="for-students-section">
      <div className="for-students-header">
        Congratulations College Graduates! <br />
        <span className="whats-next">What's Next?</span>
      </div>
      <div className="for-students-body">
        <p>
          Your perseverance and hard work has paid off. As a college graduate
          you now have an enormous amount of possibilities and opportunities.{" "}
          The life paths are infinite, and should not be reduced by the burden
          of student loan repayment.
        </p>
        <p>
          When you received your first student loan payment, we are sure the
          excitement you felt after graduation diminished. That first payment
          was an unexpected ticket into adulthood that forces you to make
          choices you never thought you would need to make after graduating:
        </p>
        <div className="graphics">
          <video autoPlay muted loop>
            <source
              src="./images/for-students/student-debt-video8.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>
          Having to face the above choices creates anxiety and depression
          instead of excitement and and optimism for recent Graduates and
          Parents of recent Graduates. Instead many face years of stress while
          trying to pay back their student loans.{" "}
        </p>
        <p>
          Our goal is to help alleviate that stress of trying to pay off your
          student loans, and bring back the optimize you once had regarding your
          future plans.{" "}
        </p>

        <RepaymentApplicationForm />
      </div>
    </div>
  );
};

export default PageForStudents;
