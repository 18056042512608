import "./homeSection1-final.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const HomeSectionOne = () => {
  return (
    <>
      <div className="home-main-sections home-section-one">
        <div className="home-section-one-main-text"></div>
        <div className="three-ways-to-help">
          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/ffbc2b/96/external-graduate-job-and-professions-avatars-tanah-basah-glyph-tanah-basah.png"
                alt="external-graduate-job-and-professions-avatars-tanah-basah-glyph-tanah-basah"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">
                  <span className="grad">Coll</span>ege Graduate / Parent{" "}
                </div>
                <div className="card-body">
                  99% of undergraduate students and/or their parents have over
                  $100k plus in college loan debt. It is estimated that the
                  amount of college loan debt for students and their parents
                  will dramatically increase year-after-year
                </div>
              </div>
            </div>
          </div>
          <div className="division-line"></div>
          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/ios-filled/ff3700/100/investment.png"
                alt="investment"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">
                  <span className="loan">Colle</span>ge Loan Crisis{" "}
                </div>
                <div className="card-body">
                  College student loan is the second highest consumer debt
                  behind mortgage, and grows higher each year.
                </div>
              </div>
            </div>
          </div>
          <div className="division-line"></div>
          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/ios-filled/00c8dc/100/helping-hand.png"
                alt="helping-hand"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">
                  <span className="debt">Coll</span>ege Debt Free Future{" "}
                </div>
                <div className="card-body">
                  Being the change we need to see for graduates and their
                  parents struggling with college loan debt
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-audience">
          <button>For Parents</button>
          <button>For High School Students</button>
          <button>For College Graduates</button>
        </div>
      </div>
    </>
  );
};

export default HomeSectionOne;
