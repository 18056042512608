const PaypalReturnPage = () => {
  return (
    <div>
      Thank you for your donation, your payment has been processed. The
      transaction details have been emailed to your email address.
    </div>
  );
};

export default PaypalReturnPage;
