import "./homeSection2.css";

const HomeSectionTwo = () => {
  return (
    <div className="home-main-sections home-section-two" id="donate-section">
      <div className="donate-content">
        <div className="overlay"></div>
        <div className="banner-donate-text">
          <div className="banner-donate-text-heading">
            <div>
              Your gift today can help <span>change</span> the life of a
              graduate and their parents
            </div>
          </div>
          <a href="/donate-bent-christy-graduate-assistance-program">
            <button>DONATE</button>
          </a>
        </div>
        <div className="banner-donate-text-image">
          <img src="./images/home/image19-bg-no-1.webp" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTwo;
