import "./PageForHighSchoolStudents.css";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Red from "./styleRed";
import GrantApplicationForm from "./grantApplicationForm";

const PageForHighSchoolStudents = () => {
  return (
    <div className="for-high-school-students-section">
      <div className="for-high-school-students-header">
        Congratulations High School Graduates! <br />
        <span className="whats-next">What's Next?</span>
      </div>
      <div className="for-high-school-students-body">
        <div className="text">
          <div className="graphics-embed">
            <div className="video-wrapper">
              <video autoPlay preload="auto" controls muted>
                <source
                  src="./images/for-students/high-school-video-5-720-compressed.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <p>
            Your perseverance and hard work has paid off. As a college graduate
            you now have an enormous amount of possibilities and opportunities.
            The life paths are infinite, and should not be reduced by the burden
            of student loan repayment.
          </p>
          <p>
            When you received your first student loan payment, we are sure the
            excitement you felt after graduation diminished. That first payment
            was an unexpected ticket into adulthood that forces you to make
            choices you never thought you would need to make after graduating:
          </p>

          <p>
            Having to face the above choices creates anxiety and depression
            instead of excitement and and optimism for recent Graduates and
            Parents of recent Graduates. Instead many face years of stress while
            trying to pay back their student loans.{" "}
          </p>
          <p>
            Our goal is to help alleviate that stress of trying to pay off your
            student loans, and bring back the optimize you once had regarding
            your future plans.{" "}
          </p>
        </div>

        <GrantApplicationForm />
      </div>
    </div>
  );
};

export default PageForHighSchoolStudents;
