import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-col rights">
        <div className="logo">
          <a href="/">
            <img src="./icons/bcgap6-wt.svg" alt="" />
          </a>
        </div>
        <div className="social-media">
          <img
            src="https://img.icons8.com/ios-filled/ffffff/100/facebook-new.png"
            alt="facebook-new"
          />
          <img
            src="https://img.icons8.com/ios-filled/100/ffffff/instagram-new--v1.png"
            alt="instagram-new--v1"
          />
          <img
            src="https://img.icons8.com/ios-filled/ffffff/100/linkedin.png"
            alt="linkedin"
          />
        </div>
      </div>

      <div className="footer-col">
        <div className="contacs">
          <div className="header"> Links</div>
          <div className="body">
            <a href="/about-bent-christy-graduate-assistance-program">
              About Us
            </a>
            <a href="/about-bent-christy-graduate-assistance-program">
              BCGAP Documentation
            </a>
            <a href="">What are BCGAP Repayment & BCGAP Grant</a>
            <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
              BCGAP High School Student Grant
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-college-students">
              BCGAP Undegraduate Student Repayment
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-parents">
              BCGAP Repayment & Grant Information for Parents
            </a>
            <a href="">Resources</a>
            <a href="/bent-christy-graduate-assistance-program-volunteer-opportunities">
              Volunteer
            </a>
            <a href="">Become a Partner</a>
            <a href="/donate-bent-christy-graduate-assistance-program">
              Donate
            </a>
          </div>
        </div>
      </div>
      <div className="footer-col">
        <div className="contacs">
          <div className="header"> Contact Us</div>
          <div className="body">
            <div className="email">Email: email@example.com</div>
            <div className="phone">Phone: (414) 123-1516</div>
            <div className="address">
              Address: 123 Your Street, New York, NY 12345
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="rights-reserved">
        ©2020 by Bent Christy Graduate Assistance Program (BCGAP)
      </div>
    </div>
  );
};

export default Footer;
