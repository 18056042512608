import "./grantApplicationForm.css";
import Red from "./styleRed";
import Bold from "./styleBold";

const GrantApplicationForm = () => {
  return (
    <div className="application-form-page">
      <div className="application-form">
        <div className="application-requirements">
          <div className="application-form-subheading">
            Application Requirements
          </div>
          <div className="application-form-content">
            To apply for the <Bold>Bent Christy High School Grant</Bold>, Aenean
            imperdiet neque a urna sollicitudin, nec fermentum ipsum varius.
            Nulla sit amet nisl sit amet magna ultricies ultricies. Suspendisse
            potenti. Sed tristique orci vel diam blandit, in facilisis ligula
            sodales. Aliquam erat volutpat. Donec ullamcorper vehicula lorem, a
            dapibus odio volutpat eget. Integer non tellus venenatis, auctor
            erat ut, pharetra ligula. Suspendisse potenti.
          </div>
          <div className="application-form-subheading">
            Application Documents
          </div>
          <div className="application-form-content">
            To apply for the <Bold>Bent Christy High School Grant</Bold>, please
            fill out the application form. Be prepared to submit the following
            documents:
            <ol>
              <li>
                <Bold>Document 1</Bold>
              </li>
              <li>
                <Bold>Document 2</Bold>
              </li>
              <ul>
                <div
                  style={{ margin: ".5em 0 .5em -1.5em", fontWeight: "600" }}
                >
                  Admissible documents:
                </div>
                <li>Document 1</li>
                <li>Document 2</li>
                <li>Document 3</li>
                <li>Document 4</li>
              </ul>
              <li>
                <Bold>Application Letter</Bold> (min. 500 words)
              </li>
              <ul>
                <div
                  style={{ margin: ".5em 0 .5em -1.5em", fontWeight: "600" }}
                >
                  In your letter, please discuss:
                </div>
                <li>Argument 1</li>
                <li>Argument 2</li>
                <li>Argument 3</li>
                <li>Argument 4</li>
                <li>Argument 5</li>
              </ul>
            </ol>
            <div className="application-form-deadline">
              Application deadline: 32 June 2028
            </div>
            <div>
              If you have any questions, please email:{" "}
              <span className="email"> email@example.com</span>.
            </div>
          </div>
        </div>
        <div className="aplication-form-wrappe">
          <div class="application-form-subheading">Application Form</div>
          <form action="" method="post">
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input type="text" id="first-name" name="first-name" required />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input type="text" id="last-name" name="last-name" required />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="year-enrolled">
              Another Field <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-enrolled"
              name="year-enrolled"
              required
            />
            <label htmlFor="application-letter">
              Another Field <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="application-letter"
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea type="text" id="comments" name="comments" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default GrantApplicationForm;
