import "./App.css";
import PageHome from "./PageHome";
import PageAbout from "./PageAbout";
import PageDonate from "./PageDonate";
import PageForStudents from "./PageForStudents";
import PageForParents from "./PageForParents";
import PageVolunteer from "./PageVolunteer";
import PageForHighSchoolStudents from "./PageForHighSchoolStudents";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeNavigation from "./navigation";
import PaypalDonate from "./paypalDonate";
import Footer from "./footer";
import PaypalReturnPage from "./paypalReturnPage";

function App() {
  return (
    <div className="App">
      <Router>
        <HomeNavigation />
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route
            path="/about-bent-christy-graduate-assistance-program"
            element={<PageAbout />}
          />
          <Route
            path="/donate-bent-christy-graduate-assistance-program"
            element={<PageDonate />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-college-students"
            element={<PageForStudents />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-parents"
            element={<PageForParents />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-high-school-students"
            element={<PageForHighSchoolStudents />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-volunteer-opportunities"
            element={<PageVolunteer />}
          />
          <Route path="/paypal-donate" element={<PaypalDonate />} />
          <Route path="/paypal-return-page" element={<PaypalReturnPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
