import { useState, useEffect, useRef } from "react";
import "./homeHeroBanner.css";
import { gsap } from "gsap";

const HomeHero = () => {
  const textRef = useRef(null);

  return (
    <div className="">
      <div className="hero-section">
        <div className="hero-image">
          <div className="home-hero-overlay"></div>
          <video className="hero-video" autoPlay muted loop>
            <source src="./video/home/video5.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="hero-heading">
          <div className="hero-header">
            <span>
              Bent Christy <br />
              Graduate <br /> Assistance <br /> Program
            </span>
            <span>
              <a href="/donate-bent-christy-graduate-assistance-program">
                <button className="btn-donate">Donate</button>
              </a>
              <a href="/#apply-section">
                <button className="btn-apply">Apply</button>
              </a>
            </span>
          </div>
          <div className="hero-subheader" ref={textRef}>
            <div className="hero-sub-wrapper">
              {" "}
              <span className="hero-sub-word">being </span>
            </div>
            <div className="hero-sub-wrapper">
              {" "}
              <span className="hero-sub-word">the change</span>
            </div>
            <div className="hero-sub-wrapper">
              {" "}
              <span className="hero-sub-word"> we need</span>
            </div>
            <div className="hero-sub-wrapper">
              {" "}
              <span className="hero-sub-word"> to see</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
