import "./paypalDonate.css";

const PaypalDonate = () => {
  return (
    <form action="https://www.paypal.com/donate" method="post" target="_blank">
      <input type="hidden" name="business" value="9BR3FDSRKPSZL" />
      <input type="hidden" name="no_recurring" value="0" />
      <input
        type="hidden"
        name="item_name"
        value="The organization cause should be written here."
      />
      <input type="hidden" name="currency_code" value="USD" />
      <input
        type="submit"
        border="0"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        className="paypal-donate-btn"
        value="Donate"
      />
      <img
        alt=""
        border="0"
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
};

export default PaypalDonate;
