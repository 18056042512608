import "./PageAbout.css";
import Bold from "./styleBold";

const About = () => {
  return (
    <>
      <div className="about-section">
        <div className="about-block">
          <div className="about-block-left">
            <div className="title">Who we are?</div>
            <div className="about-block-text">
              Bent Christy Graduate Assistance Program (BCGAP), a non-profit
              organization began in 2019 to provide financial assistance to
              undergraduates and parents struggling with college debt. Our goal
              is to give students the opportunity to a college education despite
              their financial situation. Our work is fueled by a passion for
              education, and being the change we need to see to end the college
              debt crisis. Together, we have the opportunity to empower young
              people to pursue college degree and help lighten the burden of
              college debt for graduates, parents and the next generation.
            </div>
          </div>
          <div className="about-block-right">
            <div className="about-image">
              <img src="./images/about/image103.webp" alt="" />
            </div>
          </div>
        </div>
        <div className="about-block">
          <div className="about-block-left">
            {" "}
            <div className="about-image">
              <img src="./images/about/image107.webp" alt="" />
            </div>
          </div>
          <div className="about-block-right">
            <div className="title">Our History</div>
            <div className="about-block-text">
              My parents' personal story and the stories of many others we
              talked with encouraged us to launch the BCGAP. My parents had to
              leave school at a very early age to help support the family. My
              mother’s aspiration as a young child was to become a nurse, but
              that dream was taken away because her family could not afford to
              send her to school. We have talked with parents who had to use
              their retirement savings or refinance their homes to pay-off
              college loans for their children, and graduates who are forced to
              move back home with their parents or delay starting a family
              because of college debt.{" "}
            </div>
          </div>
        </div>
        <div className="about-block">
          <div className="about-block-left">
            <div className="title">Our Mission</div>
            <div className="about-block-text">
              Bent Christy Graduate Assistance Program (BCGAP) provides
              financial assistance to undergraduates and parents to allow them
              to have a debt-free life from the burden of college loans. We have
              established a culture of empowerment and aspiration so that high
              school students have the opportunity to get a college education.
              We believe that every student must have the opportunity to a
              college education despite his or her financial circumstances.
            </div>
          </div>
          <div className="about-block-right">
            {" "}
            <div className="about-image">
              <img src="./images/about/image11.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
