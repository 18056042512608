import Red from "./styleRed";
import "./volunteerInterestRequest.css";
import { useState, useEffect } from "react";
import { useRef } from "react";

const VolunteerInterestRequest = () => {
  const [volunteerRole, setVolunteerRole] = useState("Select a role:");
  const [dropdownClosed, setDropdownClosed] = useState(true);
  const dropdownRef = useRef(null);

  const roles = [
    "Board member",
    "Board secretary",
    "Treasurer",
    "Fundraiser",
    "Vice president",
    "Grant application writer",
    "Other",
  ];

  const handleRoleSelect = (role) => {
    setVolunteerRole(role);
    setDropdownClosed(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownClosed(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="interest">
      <div className="interest-subheader">Get in touch with us!</div>
      <form action="" method="post">
        <label htmlFor="full-name">
          Full Name <Red> *</Red>
        </label>
        <input type="text" id="full-name" name="full-name" required />
        <label htmlFor="email">
          Email <Red> *</Red>
        </label>
        <input type="email" id="email" name="email" required />
        <label htmlFor="volunteer-opportunity">
          Volunteering opportunity <Red> *</Red>
        </label>
        <div className="menu-dropdown-container">
          <div
            className="menu-dropdown-select"
            onClick={() => setDropdownClosed(!dropdownClosed)}
          >
            {volunteerRole}
          </div>
          <div
            className="menu-dropdown-all-options"
            ref={dropdownRef}
            style={{ display: dropdownClosed ? "none" : "block" }}
          >
            {roles.map((role, index) => (
              <div
                key={index}
                className="menu-dropdown-option"
                onClick={() => handleRoleSelect(role)}
              >
                {role}
              </div>
            ))}
          </div>
        </div>
        <label htmlFor="full-name">If you selected Other:</label>
        <input type="text" id="other-role" name="other-role" />
        <label htmlFor="request">
          Message <Red> *</Red>
        </label>
        <textarea type="text" id="request" name="request" rows="5" required />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default VolunteerInterestRequest;
