import "./PageVolunteer.css";
import VolunteerInterestRequest from "./volunteerInterestRequest";
import Bold from "./styleBold";

const PageVolunteer = () => {
  return (
    <div className="volunteer-section">
      <div className="wrapper-left">
        <div className="volunteer-header">Volunteer with us!</div>
        <div className="image-wrapper">
          <img src="./images/volunteering/image-vol.jpg" alt="" />
        </div>
        <div className="volunteer-content">
          {" "}
          <ul>
            <li>vice president</li>
            <li>board secretary</li>
            <li>fundraiser</li>
            <li>grant application writer</li>
            <li>board member</li>
            <li>treasurer</li>

            <li class="other-li">
              {" "}
              <Bold>... and other roles!</Bold>
            </li>
          </ul>
        </div>
      </div>
      <div className="wrapper-right">
        <VolunteerInterestRequest />
      </div>
    </div>
  );
};

export default PageVolunteer;
