import "./PageDonate.css";
import { useState, useEffect } from "react";
import Bold from "./styleBold";
import PaypalDonate from "./paypalDonate";

const PageDonate = () => {
  const donateImages = [
    "./images/donate/donation3.webp",
    "./images/donate/donation4.webp",
    "./images/donate/donation2.webp",
    "./images/donate/donation7.webp",
    "./images/donate/donation8.webp",
    "./images/donate/donation9.webp",
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [move, setMove] = useState(-100);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % donateImages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [donateImages.length]);

  const visibleImages = [];
  for (let i = 0; i < 7; i++) {
    visibleImages.push(donateImages[(currentImage + i) % donateImages.length]);
  }

  const visibleImages2 = [];
  for (let i = 0; i < 7; i++) {
    visibleImages2.push(donateImages[i]);
  }

  useEffect(() => {
    const intervalMove = setInterval(() => {
      if (move <= -2500) {
        setMove(1000);
      } else {
        setMove((prevMove) => prevMove - 1);
      }
    }, 14);

    return () => clearInterval(intervalMove);
  }, [move]);

  return (
    <>
      <div className="donate-section">
        <div class="loop">
          <div className="image-container-one">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
          <div className="image-container-two">
            {visibleImages2 &&
              visibleImages2.map((image, index) => (
                <img key={index} src={image} alt="" />
              ))}
          </div>
        </div>
        <div className="donate-options">
          <div className="donate-option">
            <div className="donate-section-header">$1 Fundraising</div>
            <div className="donate-section-text">
              College loan is the second highest consumer debt therefore, we
              believe that if everyone gives $1 to support this cause it will
              help to eliminate college loan burden for million and future
              generation
            </div>
            <div className="donate-btn">
              <PaypalDonate />
            </div>
          </div>
          <div className="donate-option">
            <div className="donate-section-header">One-Time Donation</div>
            <div className="donate-section-text">
              Your generosity makes a difference! By contributing a one-time
              donation of any amount, you help us continue our vital work and
              impact the lives of those in need. Whether it’s $10, $50, or $100,
              every dollar counts and brings us closer to achieving our goals.
            </div>
            <div className="donate-btn">
              <PaypalDonate />
            </div>
          </div>
          <div className="donate-option">
            <div className="donate-section-header">
              Become a Monthly Supporter
            </div>
            <div className="donate-section-text">
              Will you consider becoming one of our valued monthly supporters by
              converting your $55 contribution into a monthly donation? Ongoing
              monthly donations allow us to better focus on our mission.
            </div>
            <div className="donate-btn">
              <PaypalDonate />
            </div>
          </div>
        </div>

        <div
          className="corporate-section-heading"
          id="donate-corporate-section"
        >
          Other Ways to Contribute
        </div>
        <div className="donate-options-corporate-wrapper">
          <div className="donate-option-corporate">
            <div className="donate-option-corporate-image">
              <div className="overlay"></div>
              <img src="./images/donate/donate-corporate.jpg" alt="" />
            </div>
            <div className="donate-option-corporate-header">
              Employer Matching
            </div>
            <div className="donate-option-corporate-content">
              Did you know many employers offer corporate giving matching whcih
              can multiply your gift and our impat for college studentts and
              their parents?
              <ol>
                <li>
                  <Bold>Ask Your HR:</Bold> Find out if your company matches
                  donations to charities.
                </li>
                <li>
                  <Bold>Get the Form:</Bold> Get the matching gift form or link
                  from your HR department.
                </li>
                <li>
                  <Bold>Make a Donation:</Bold> Donate to the charity that helps
                  college students and their parents, and save your donation
                  receipt.
                </li>
                <li>
                  {" "}
                  <Bold>Fill Out the Form:</Bold> Complete the form with details
                  of your donation and submit it to your company.
                </li>
                <li>
                  <Bold>Follow Up:</Bold> Check with your company to make sure
                  they send the matching donation to the charity.
                </li>
              </ol>
            </div>
          </div>
          <div className="division-line"></div>
          <div className="donate-option-corporate">
            <div className="donate-option-corporate-image">
              <div className="overlay"></div>
              <img src="./images/donate/donate-corporate3.jpg" alt="" />
            </div>
            <div className="donate-option-corporate-header">
              Stock Gift /Planning Giving
            </div>
            <div className="donate-option-corporate-content">
              Making a gift of securities or consider including BCGAP in your
              estate planning offer a unique opportunity for philanthropy, and a
              great future possible for countless college graduates and their
              parents for years to come
              <ol>
                <li>
                  <Bold>Consult a Financial Advisor:</Bold> Talk to a financial
                  advisor to understand how donating stocks or including a
                  charity in your estate plan works and the benefits.
                </li>
                <li>
                  <Bold> Contact BCGAP:</Bold> Reach out to BCGAP to get details
                  on how to transfer stocks or include them in your estate
                  planning.
                </li>
                <li>
                  <Bold> Transfer Stocks:</Bold> If donating stocks, work with
                  your financial institution to transfer the shares to BCGAP’s
                  account.
                </li>
                <li>
                  <Bold>Update Your Estate Plan:</Bold> If including BCGAP in
                  your will or trust, work with your attorney to update your
                  estate documents to specify your donation.
                </li>
                <li>
                  <Bold>Notify BCGAP:</Bold> Let BCGAP know about your gift or
                  planned giving so they can acknowledge and plan for your
                  generous contribution.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="donate-contact-us">Have questions? Contact us!</div>
      </div>
    </>
  );
};

export default PageDonate;
