import "./PageForParents.css";
import Red from "./styleRed";
import Bold from "./styleBold";

const PageForParents = () => {
  return (
    <div className="parents-section">
      <div className="parents-section-block">
        <div className="parents-section-block-content left">
          <div className="parents-section-block-content-header">
            Ubi est bibliotheca tua?
          </div>
          <div className="parents-section-block-content-text">
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            auctor, justo vel tincidunt vehicula, justo sapien efficitur sapien,
            eu convallis risus ex vel elit. Suspendisse potenti. Phasellus
            luctus, ligula non aliquet sollicitudin, ligula erat tincidunt
            libero, at dignissim lacus sapien vel felis. Mauris faucibus, orci
            in vestibulum dictum, odio arcu tincidunt lectus, at gravida velit
            justo id leo. Integer non nunc sed orci sollicitudin malesuada.
            Fusce tincidunt, erat ac porttitor sollicitudin, nunc augue suscipit
            lectus, sed malesuada dolor tortor a urna. Sed sit amet accumsan
            leo, sed vehicula purus. Ligula non aliquet sollicitudin, ligula
            erat tincidunt libero, at dignissim lacus sapien vel felis. Integer
            non nunc sed orci sollicitudin malesuada.
          </div>
        </div>
        <div className="parents-section-block-image right">
          <img
            src="./images/parents/parents1.webp"
            alt="bent-christy-grant-college-degree"
          />
        </div>
      </div>
      <div className="parents-section-block">
        <div className="parents-section-block-image left">
          <img
            src="./images/parents/parents2.webp"
            alt="bent-christy-grant-college-degree"
          />
        </div>
        <div className="parents-section-block-content right">
          <div className="parents-section-block-content-header">
            Quid est nomen tuum?
          </div>
          <div className="parents-section-block-content-text">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              auctor, justo vel tincidunt vehicula, justo sapien efficitur
              sapien, eu convallis risus ex vel elit. Suspendisse potenti.
              Phasellus luctus, ligula non aliquet sollicitudin, ligula erat
              tincidunt libero, at dignissim lacus sapien vel felis. Mauris
              faucibus, orci in vestibulum dictum, odio arcu tincidunt lectus,
              at gravida velit justo id leo. Integer non nunc sed orci
              sollicitudin malesuada. Fusce tincidunt, erat ac porttitor
              sollicitudin, nunc augue suscipit lectus, sed malesuada dolor
              tortor a urna. Sed sit amet, sed vehicula purus. Ligula non
              aliquet sollicitudin, ligula erat tincidunt libero, at dignissim
              lacus sapien vel felis. Justo tincidunt vehicula, justo sapien
              efficitur sapien, eu convallis risus ex vel elit.
            </p>
          </div>
        </div>
      </div>

      <div className="parents-apply-section">
        <div className="parents-apply-section-requirements">
          <div className="parents-apply-section-header">
            Application Requirements
          </div>
          <p>
            <Bold>BCGAP Graduate Assistance Repayment </Bold>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod
            malesuada. Maecenas sit amet velit quis arcu pulvinar vehicula.
            Nulla facilisi. Aenean imperdiet neque a urna sollicitudin, nec
            fermentum ipsum varius. Nulla sit amet nisl sit amet magna ultricies
            ultricies. Suspendisse potenti. Sed tristique orci vel diam blandit,
            in facilisis ligula sodales.
          </p>
          <p>
            <Bold>BCGAP High School Grant </Bold>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod
            malesuada. Maecenas sit amet velit quis arcu pulvinar vehicula.
            Nulla facilisi. Aenean imperdiet neque a urna sollicitudin, nec
            fermentum ipsum varius. Nulla sit amet nisl sit amet magna ultricies
            ultricies. Suspendisse potenti. Sed tristique orci vel diam blandit,
            in facilisis ligula sodales.
          </p>
        </div>

        <div className="parents-apply-section-form">
          <div className="parents-apply-section-header">Application Form</div>
          <form action="" method="post">
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input type="text" id="first-name" name="first-name" required />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input type="text" id="last-name" name="last-name" required />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="year-enrolled">
              Another Field <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-enrolled"
              name="year-enrolled"
              required
            />
            <label htmlFor="application-letter">
              Another Field <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="application-letter"
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea type="text" id="comments" name="comments" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForParents;
