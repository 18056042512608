import "./repaymentApplicationForm.css";
import Red from "./styleRed";
import Bold from "./styleBold";

const RepaymentApplicationForm = () => {
  return (
    <div className="application-form-page">
      <div className="application-form">
        <div className="application-requirements">
          <div className="application-requirements-wrapper">
            <div className="application-form-subheading">
              BCGAP Repayment Requirements
            </div>
            <p>
              Haec borsa studiosis insignis est qui excelsis notis et studio
              praestant. Opportunitas singularis est ad discendum, crescendum,
              et futurum promittendum. Petitores debent demonstrare
              dedicationem, industriam, et potentiale ad beneficium
              communitatis. Cum hac borsa, poteris accedere ad res optimas
              academicas et culturales, et ad summos magistros, mentores, et
              disciplinas. Noli deesse hanc occasionem ad metam tuam academicam
              pervenire et magnas res in vita efficere. Applicatio nunc aperta
              est.
            </p>
            <p>
              <Bold>
                You are encouraged to apply for the BCGAP Repayment, if:
              </Bold>{" "}
            </p>
            <ul>
              <li>you are a recent undegraduate student</li>
              <li>
                you completed your degree in 4 years <Red> *</Red>
              </li>
              <li>you have a student debt</li>
              <li>
                you and/or your family experience hardship due to student debt
                repayments
              </li>
            </ul>
            <p className="footnote">
              <i>
                <Red>*</Red> If personal hardships (such as caring for an ill
                parent, financial need, etc.) delayed your graduation, you are
                still eligible to apply. Please detail your hardship in your
                application materials.{" "}
              </i>
            </p>
          </div>

          <div className="application-form-subheading">
            Application Documents
          </div>
          <div className="application-form-content">
            To apply for the{" "}
            <Bold>Bent Christy Graduate Assistance Program</Bold>, please fill
            out the application form. Be prepared to submit the following
            documents:
            <ol>
              <li>
                <Bold>Unofficial College Transcript</Bold> (the Official
                Transcript will be requested as a proof, if you are selected for
                the BCGAP Repayment grant)
              </li>
              <li>
                <Bold>Proof of financial need</Bold>
              </li>
              <ul>
                <div
                  style={{ margin: ".5em 0 .5em -1.5em", fontWeight: "600" }}
                >
                  Admissible documents:
                </div>
                <li>Document 1</li>
                <li>Document 2</li>
                <li>Document 3</li>
                <li>Document 4</li>
              </ul>
              <li>
                <Bold>Application Letter</Bold> (min. 500 words)
              </li>
              <ul>
                <div
                  style={{ margin: ".5em 0 .5em -1.5em", fontWeight: "600" }}
                >
                  In your letter, please discuss:
                </div>
                <li>what encouraged you to pursue a college degree?</li>
                <li>
                  did you complete your degree in four years? If you experienced
                  hardships which delayed your graduation, please provide the
                  details.
                </li>
                <li>how did you pay for your college degree?</li>
                <li>
                  what sacrificies did you and/or your family had to make to pay
                  for your college degree?
                </li>
                <li>
                  if you are selected, how will the BCGAP Repayment grant help
                  you to achieve your personal, academic, and/or professional
                  goals?
                </li>
              </ul>
            </ol>
            <div className="application-form-deadline">
              Application deadline: 32 June 2028
            </div>
            <div>
              If you have any questions, please email:{" "}
              <span className="email"> email@example.com</span>.
            </div>
          </div>
        </div>
        <div className="aplication-form-wrapper">
          <div class="application-form-subheading">Application Form</div>
          <form action="" method="post">
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input type="text" id="first-name" name="first-name" required />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input type="text" id="last-name" name="last-name" required />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="student-status">
              Status <Red>*</Red>
            </label>
            <select name="student-status" id="student-status">
              <option value="select">Select your status:</option>
              <option value="student">College Student</option>
              <option value="parent">Parent</option>
            </select>
            <label htmlFor="college-attended">
              College Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-attended"
              name="college-attended"
              required
            />
            <label htmlFor="major-in-college">
              College Major (and Minor, if applicable) <Red>*</Red>
            </label>
            <input
              type="text"
              id="major-in-college"
              name="major-in-college"
              required
            />
            <label htmlFor="year-enrolled">
              Year Enrolled <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-enrolled"
              name="year-enrolled"
              required
            />
            <label htmlFor="year-completed">
              Year Completed <Red>*</Red>
            </label>
            <input
              type="text"
              id="year-completed"
              name="year-completed"
              required
            />
            <label htmlFor="college-transcript">
              Unofficial Transcript <Red>*</Red>
            </label>
            <input
              type="file"
              id="college-transcript"
              name="college-transcript"
              required
            />
            <label htmlFor="proof-of-need">
              Proof of Financial Need <Red>*</Red>
            </label>
            <input
              type="file"
              id="proof-of-need"
              name="proof-of-need"
              required
            />
            <label htmlFor="application-letter">
              Application Letter <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-letter"
              name="application-letter"
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea type="text" id="comments" name="comments" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default RepaymentApplicationForm;
