import "./homeSection4.css";
import { ReactComponent as Frame } from "./assets/frame-wave.svg";
import Bold from "./styleBold";

const HomeSectionFour = () => {
  const windowWidth = window.innerWidth;

  const breakLine = () => {
    if (windowWidth < 1000) {
      return (
        <>
          <div style={{ padding: "0.5em 0" }}> </div>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="home-main-sections section-four">
      <div className="home-section-four-block-wrapper-full-screen">
        <div className="home-section-four-block-wrapper">
          <div className="heading">
            <div className="title">
              Who We Are <br /> & What We Do{" "}
            </div>
            <div className="image-wrapper">
              <div className="image-container">
                <img src="./images/home/headshot1.webp" alt="" />
              </div>
              <div className="image-container">
                <img src="./images/home/headshot2.webp" alt="" />
              </div>
              <div className="image-container">
                <img src="./images/home/headshot3.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="division-line"></div>
          <div className="content">
            <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>, a
            non-profit organization, began in 2019 to provide financial
            assistance to undergraduates and parents struggling with college
            debt. Our goal is to give students the opportunity to a college
            education despite their financial situation. {breakLine()} In{" "}
            <u>the 19XXs (decade?)</u>, my parents had to leave school at a very
            early age to help support the family. My mother's aspiration as a
            young child was to become a nurse, but that dream was taken away
            because her family could not afford to send her to school. Since the{" "}
            <u>19XXs (decade?)</u>, the situation has improved but not for
            everyone. {breakLine()} Recently, we have met high school students
            with big dream for a college education, but those dreams were short
            lived because of their financial circumstances. We have talked with
            parents who had to use their retirement savings or refinance their
            homes to pay-off college loans for their children. We talked to
            graduates who are forced to move back home with their parents or
            delay starting a family because of college debt. {breakLine()} Our
            work is fueled by a passion for education, and being the change we
            need to see to end the college debt crisis. Together, we can empower
            young people to pursue college education and help lighten the burden
            of college debt for graduates, parents and the next generation.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFour;
