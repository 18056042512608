import "./homeSection5.css";
import { Link, useNavigate } from "react-router-dom";

const HomeSectionFive = () => {
  const navigate = useNavigate();

  return (
    <div className="home-main-sections" id="apply-section">
      <div className="home-section-five">
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title"> </div>
          <div className="content">
            <div>WHAT ARE BCGAP REPAYMENT & BCGAP GRANT?</div>
            <div className="apply">
              Learn more
              <img
                src="https://img.icons8.com/pastel-glyph/64/ffffff/circled-right.png"
                alt="circled-right"
              />
            </div>
          </div>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For High School Students</div>
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>

          <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
            <div className="apply">
              How to Apply{" "}
              <img
                src="https://img.icons8.com/pastel-glyph/64/fab900/circled-right.png"
                alt="circled-right"
              />
            </div>
          </a>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For Undergraduate Students </div>
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            eleifend risus ac nulla viverra, vitae venenatis ex pulvinar. Nulla
            facilisi. Suspendisse potenti. Fusce sodales eros eu tincidunt
            placerat.
          </div>
          <a href="/bent-christy-graduate-assistance-program-for-college-students">
            <div className="apply">
              How to Apply{" "}
              <img
                src="https://img.icons8.com/pastel-glyph/64/00c8dc/circled-right.png"
                alt="circled-right"
              />
            </div>
          </a>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For Parents</div>
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
            risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing
            nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas
            ligula massa, varius a, semper congue, euismod non, mi.
          </div>
          <div className="apply">
            How to Apply
            <img
              src="https://img.icons8.com/pastel-glyph/64/e81212/circled-right.png"
              alt="circled-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFive;
