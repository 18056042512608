import HomeHeroBanner from "./homeHeroBanner";
import HomeSectionOne from "./homeSection1-final";
import HomeSectionTwo from "./homeSection2";
import HomeSectionThree from "./homeSection3";
import Footer from "./footer";
import HomeSectionFour from "./homeSection4";
import HomeSectionFive from "./homeSection5";

const PageHome = () => {
  return (
    <div>
      <HomeHeroBanner />
      <HomeSectionOne />
      <HomeSectionFour />
      <HomeSectionFive />
      <HomeSectionTwo />
      <HomeSectionThree />
    </div>
  );
};

export default PageHome;
